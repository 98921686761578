import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent implements OnInit {

  masterForm: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private _cookieService: CookieService,
    private _dataService: DataService,
    public _dialogRef: MatDialogRef<NewComponent>
    ) {
    this.masterForm = this._formBuilder.group({
      type: ['', Validators.required],
      name: ['', Validators.required]
    })
   }

  ngOnInit(): void {
  }

  onSubmit(){
    if(this.masterForm.invalid){
      this.openSnackBar("Enter Required Fields","OK");
    }
    else{
      this._dataService.addMasterData(this.masterForm.controls.type.value,this.masterForm.controls.name.value,this.getCookie('userid'),new Date()).subscribe(res=>{
        if(res['status']){
          this.openSnackBar("Master Data Added","OK");
          this.masterForm.reset();
          this._dialogRef.close(true)
        }
        else{
          this.openSnackBar("Error, try again","OK");
        }
      })
    }
  }

  onCancel(){
    this._dialogRef.close(false)
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  getCookie(cname) {
    return this._cookieService.get(cname);
  }

}
