<p style="text-align: center; font-weight: 500; font-size: 16px;">ADD MASTERDATA</p>

<form [formGroup]="masterForm">
    <div>
        <mat-form-field appearance="outline">
            <mat-label>Type</mat-label>
            <mat-select formControlName="type">
                <mat-option value="P">Place</mat-option>
                <mat-option value="V">Village</mat-option>
                <mat-option value="T">Post</mat-option>
                <mat-option value="D">District</mat-option>
                <mat-option value="X">Panchayath</mat-option>
                <mat-option value="Y">Municipality</mat-option>
                <mat-option value="Z">Corporation</mat-option>
                <mat-option value="I">Idproof</mat-option>
                <mat-option value="CO">Committy Member</mat-option>
            </mat-select>
            <!-- <mat-error *ngIf=""></mat-error> -->
        </mat-form-field>
    </div>

    <div>
        <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" formControlName="name">
        </mat-form-field>
    </div>
</form>

<div>
    <button mat-raised-button color="primary" (click)="onSubmit()">Add</button>

    <button mat-stroked-button color="primary" (click)="onCancel()" style="margin-left: 50px;">Cancel</button>
</div>