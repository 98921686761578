import { Time } from '@angular/common';
import { prop } from '@rxweb/reactive-form-validators';

export class applicationStatus {

@prop()
section: string;

@prop()
status: string;

@prop()
appid: number;

@prop()
userid: number;

@prop()
branchid: number;

@prop()
transdate: Date;

@prop()
crdate: Date;

@prop()
crtime: string;

}