import { prop } from '@rxweb/reactive-form-validators';

export class TransactionForm {
    @prop()
    branchid: number;
    
    @prop()
    trmasterid: number;

    @prop()
    transtype: string;

    @prop()
    paymentmode: string;

    @prop()
    principle: number = 0;

    @prop()
    interest: number = 0;

    @prop()
    penal: number = 0;

    @prop()
    notice: number = 0;

    @prop()
    afcharge: number = 0;

    @prop()
    efcharge: number = 0;

    @prop()
    misc: number = 0;

    @prop()
    servicecharge: number = 0;

    @prop()
    stationary: number = 0;

    @prop()
    passbookfee: number = 0;

    @prop()
    insurance: number = 0;

    @prop()
    others: number = 0;

    @prop()
    userid: number;

    @prop()
    crdate: string;

    @prop()
    crtime: string;

    @prop()
    verifyid: number = 0;

    @prop()
    cashierid: number;

    @prop()
    encashdate: string;

    @prop()
    encashtime: string;

    @prop()
    verifydate: string;

    @prop()
    verifytime: string;

    @prop()
    trdate: string;

    @prop()
    section: string;

    @prop()
    accountid: number;

    @prop()
    accountno: string;

    @prop()
    customerid: number;

    @prop()
    memberno: number;

    @prop()
    customer: string;

    @prop()
    hname: string;

    @prop()
    cancelflag: string = 'N';

    @prop()
    canceldate: string;

    @prop()
    canceldesc: string;
}